import {create} from 'zustand';
import {ethers} from "ethers";
import {WalletConnectConnector} from '@web3-react/walletconnect-connector';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {logDOM} from "@testing-library/react";

export const bscTestnet = {
    id: 97,
    name: 'BNB Smart Chain Testnet',
    network: 'BNB Smart Chain Testnet',
    nativeCurrency: {
        decimals: 18,
        name: 'BNB',
        symbol: 'tBNB',
    },
    rpcUrls: {
        public: {http: ['https://data-seed-prebsc-1-s1.binance.org:8545/']},
        default: {http: ['https://data-seed-prebsc-1-s1.binance.org:8545/']},
    },
    blockExplorers: {
        etherscan: {name: 'SnowTrace', url: 'https://testnet.bscscan.com/'},
        default: {name: 'SnowTrace', url: 'https://testnet.bscscan.com/'},
    }
};
const bnbProvider = new ethers.providers.JsonRpcProvider("https://data-seed-prebsc-1-s1.binance.org:8545/");
const address_pool = '0x89FDfc7e11B7c43251C0E1bE24258B203E6E21Ae';
const abi_pool = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "Invest",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_refFather",
                "type": "address"
            }
        ],
        "name": "Invest_in_DEGY",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "Sale_ended",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "Sale_started",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address_nft",
                "type": "address"
            }
        ],
        "name": "change_nft_col",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "end_sale",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "get_invest_back",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "get_my_NFT",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "get_user_board",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_user",
                "type": "address"
            }
        ],
        "name": "get_user_info",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "address[]",
                "name": "_arr",
                "type": "address[]"
            }
        ],
        "name": "get_user_place_in_arr",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_user",
                "type": "address"
            },
            {
                "internalType": "address[]",
                "name": "_array_users",
                "type": "address[]"
            }
        ],
        "name": "is_used_var_address",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nft_count",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address payable",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "start_sale",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token_nft",
        "outputs": [
            {
                "internalType": "contract IERC721_s",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const walletconnect = new WalletConnectConnector({
    rpc: {97: 'https://data-seed-prebsc-1-s3.binance.org:8545'},
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: 12000,
});

export const useAuthStore = create((set, get) => ({
    user: null,
    userInfo: [0, 0, 0, 0, 0, 0],
    balance: null,
    connectedTo: null,
    provider: null,
    contract: null,
    daiContractWithSigner: null,
    board: [],

    connectToMetamask: async () => {
        if (window.ethereum) {
            try {
                if (window.ethereum) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);

                    // Получаем идентификатор сети
                    const { chainId } = await provider.getNetwork();

                    // BNB Smart Chain Mainnet имеет chainId 56, Testnet — 97
                    if (chainId === 56) {
                        console.log("Пользователь подключен к BNB Smart Chain Mainnet");
                    } else if (chainId === 97) {
                        console.log("Пользователь подключен к BNB Smart Chain Testnet");
                    } else {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainId: `0x${bscTestnet.id.toString(16)}`, // ID сети в формате HEX
                                chainName: bscTestnet.name,
                                nativeCurrency: {
                                    name: bscTestnet.nativeCurrency.name,
                                    symbol: bscTestnet.nativeCurrency.symbol,
                                    decimals: bscTestnet.nativeCurrency.decimals,
                                },
                                rpcUrls: bscTestnet.rpcUrls,
                                blockExplorerUrls: [bscTestnet.blockExplorers.default.url],
                            }],
                        });
                    }
                } else {
                    console.log("MetaMask не установлен");
                }

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send('eth_requestAccounts', []);
                const signer = provider.getSigner();
                const accounts = await signer.getAddress();
                const balance = await provider.getBalance(accounts);
                const ethBalance = ethers.utils.formatEther(balance);

                try {
                    const chainId = await provider.getNetwork()
                    if (chainId.chainId != 97) {  // 97 fot BSC
                        return true
                    }
                } catch (err) {
                    console.info('err', err.message);
                    return false
                }
                set({user: accounts, provider, balance: ethBalance, connectedTo: 'metamask'});

                localStorage.setItem('isConnected', 'true');
                localStorage.setItem('account', accounts);
                localStorage.setItem('connectedTo', 'metamask');
            } catch (err) {
                console.error('Error connecting to MetaMask...', err);
            }
        } else {
            console.log('MetaMask not detected');
        }
    },

    connectToBinance: async () => {
        if (window.BinanceChain) {
            try {
                if (window.ethereum) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);

                    // Получаем идентификатор сети
                    const { chainId } = await provider.getNetwork();

                    // BNB Smart Chain Mainnet имеет chainId 56, Testnet — 97
                    if (chainId === 56) {
                        console.log("Пользователь подключен к BNB Smart Chain Mainnet");
                    } else if (chainId === 97) {
                        console.log("Пользователь подключен к BNB Smart Chain Testnet");
                    } else {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainId: `0x${bscTestnet.id.toString(16)}`, // ID сети в формате HEX
                                chainName: bscTestnet.name,
                                nativeCurrency: {
                                    name: bscTestnet.nativeCurrency.name,
                                    symbol: bscTestnet.nativeCurrency.symbol,
                                    decimals: bscTestnet.nativeCurrency.decimals,
                                },
                                rpcUrls: bscTestnet.rpcUrls,
                                blockExplorerUrls: [bscTestnet.blockExplorers.default.url],
                            }],
                        });
                    }
                } else {
                    console.log("MetaMask не установлен");
                }
                const provider = new ethers.providers.Web3Provider(window.BinanceChain);
                await window.BinanceChain.enable();
                const signer = provider.getSigner();
                const accounts = await signer.getAddress();
                const balance = await provider.getBalance(accounts);
                const ethBalance = ethers.utils.formatEther(balance);

                set({user: accounts, provider, balance: ethBalance, connectedTo: 'binance'});

                localStorage.setItem('isConnected', 'true');
                localStorage.setItem('account', accounts);
                localStorage.setItem('connectedTo', 'binance');

                console.log('Connected to Binance Smart Chain');
            } catch (error) {
                console.error('User denied account access or error occurred:', error);
            }
        } else {
            console.error('Binance Chain Wallet is not installed');
        }
    },
    connectToWallet: async () => {
        const isMobileDevice = () => {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        };
        const provider = new WalletConnectProvider({
            rpc: {
                97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
            },
            qrcode: !isMobileDevice(), // Показываем QR-код только на десктопе
            qrcodeModalOptions: {
                mobileLinks: ["metamask", "trust", "rainbow", "argent", "imtoken", "pillar"], // Список мобильных кошельков
            },
        });

        try {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);

                // Получаем идентификатор сети
                const { chainId } = await provider.getNetwork();

                // BNB Smart Chain Mainnet имеет chainId 56, Testnet — 97
                if (chainId === 56) {
                    console.log("Пользователь подключен к BNB Smart Chain Mainnet");
                } else if (chainId === 97) {
                    console.log("Пользователь подключен к BNB Smart Chain Testnet");
                } else {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: `0x${bscTestnet.id.toString(16)}`, // ID сети в формате HEX
                            chainName: bscTestnet.name,
                            nativeCurrency: {
                                name: bscTestnet.nativeCurrency.name,
                                symbol: bscTestnet.nativeCurrency.symbol,
                                decimals: bscTestnet.nativeCurrency.decimals,
                            },
                            rpcUrls: bscTestnet.rpcUrls,
                            blockExplorerUrls: [bscTestnet.blockExplorers.default.url],
                        }],
                    });
                }
            } else {
                console.log("MetaMask не установлен");
            }
            // Включаем WalletConnect, это покажет QR-код пользователю
            await provider.enable();

            const web3Provider = new ethers.providers.Web3Provider(provider);
            const signer = provider.getSigner();
            const accounts = await signer.getAddress();
            const balance = await provider.getBalance(accounts);
            const ethBalance = ethers.utils.formatEther(balance);

            set({ user: accounts, provider: web3Provider, balance: ethBalance, connectedTo: 'wallet' });

            localStorage.setItem('wcIsConnected', 'true');
            localStorage.setItem('account', accounts);
        } catch (ex) {
            console.error(ex);
        }
    },
    connectToTrust: async () => {
        const isMobileDevice = () => {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        };
        const provider = new WalletConnectProvider({
            rpc: {
                97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
            },
            qrcode: !isMobileDevice(), // Показываем QR-код только на десктопе
            qrcodeModalOptions: {
                mobileLinks: ["metamask", "trust", "rainbow", "argent", "imtoken", "pillar"], // Список мобильных кошельков
            },
        });
        try {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);

                // Получаем идентификатор сети
                const { chainId } = await provider.getNetwork();

                // BNB Smart Chain Mainnet имеет chainId 56, Testnet — 97
                if (chainId === 56) {
                    console.log("Пользователь подключен к BNB Smart Chain Mainnet");
                } else if (chainId === 97) {
                    console.log("Пользователь подключен к BNB Smart Chain Testnet");
                } else {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: `0x${bscTestnet.id.toString(16)}`, // ID сети в формате HEX
                            chainName: bscTestnet.name,
                            nativeCurrency: {
                                name: bscTestnet.nativeCurrency.name,
                                symbol: bscTestnet.nativeCurrency.symbol,
                                decimals: bscTestnet.nativeCurrency.decimals,
                            },
                            rpcUrls: bscTestnet.rpcUrls,
                            blockExplorerUrls: [bscTestnet.blockExplorers.default.url],
                        }],
                    });
                }
            } else {
                console.log("MetaMask не установлен");
            }

            await provider.enable();

            const web3Provider = new ethers.providers.Web3Provider(provider);
            const signer = provider.getSigner();
            const accounts = await signer.getAddress();
            const balance = await provider.getBalance(accounts);
            const ethBalance = ethers.utils.formatEther(balance);

            set({ user: accounts, provider: web3Provider, balance: ethBalance, connectedTo: 'wallet' });

            // Сохраняем статус подключения в localStorage
            localStorage.setItem('wcIsConnected', 'true');
            localStorage.setItem('account', accounts);
        } catch (ex) {
            console.error(ex);
        }
    },
    checkConnection: async () => {
        const isConnected = localStorage.getItem('isConnected');
        const savedAccount = localStorage.getItem('account');
        const connectedTo = localStorage.getItem('connectedTo');
        if (isConnected && window.ethereum) {
            if (connectedTo === 'metamask') {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const accounts = await signer.getAddress();

                    if (savedAccount === accounts) {
                        set({ user: accounts, provider, connectedTo });
                    } else {
                        set({ user: null, provider: null, connectedTo: null });
                    }
                } catch (error) {
                    console.error('Error checking connection:', error);
                    set({ user: null, provider: null });
                }
            } else if (connectedTo === 'binance') {
                try {
                    const provider = new ethers.providers.Web3Provider(window.BinanceChain);
                    await window.BinanceChain.enable();
                    const signer = provider.getSigner();
                    const accounts = await signer.getAddress();

                    if (savedAccount === accounts) {
                        set({ user: accounts, provider, connectedTo });
                    } else {
                        set({ user: null, provider: null, connectedTo: null });
                    }
                } catch (error) {
                    console.error('Error checking connection:', error);
                    set({ user: null, provider: null });
                }
            }
        }
    },

    connectToContract: async () => {
        const {provider} = get();
        if (!provider) {
            return;
        }

        const signer = provider.getSigner();
        const contract = new ethers.Contract(address_pool, abi_pool, signer);
        const daiContractWithSigner = contract.connect(signer);

        set({contract});
        set({daiContractWithSigner});
    },

    connectToPublicProviderContract: async () => {
        const contract = new ethers.Contract(address_pool, abi_pool, bnbProvider);

        try {
            let result = await contract.get_user_board();

            result =[result[0], result[1].map((item) => {
                return ethers.utils.formatUnits(item, 18)
            })]

            result = result[0].map((key, index) => {
                return { [key]: parseFloat(result[1][index]) };
            });

            result.sort((a, b) => Object.values(b)[0] - Object.values(a)[0]);

            set({board: result})
        } catch (error) {
            console.error('Error calling get_user_board:', error);
        }

        set({contract});
    },

    // Получить лидерборд
    getUserBoard: async () => {
        const {contract, provider, user} = get();
        if (!contract) {
            console.error('Contract is not connected');
            return;
        }

        try {
            let result = await contract.get_user_board();

            result =[result[0], result[1].map((item) => {
                return ethers.utils.formatUnits(item, 18)
            })]

            result = result[0].map((key, index) => {
                return { [key]: parseFloat(result[1][index]) };
            });

            result.sort((a, b) => Object.values(b)[0] - Object.values(a)[0]);

            const balance = await provider.getBalance(user);
            const ethBalance = ethers.utils.formatEther(balance);
            // console.log('combined', combined);
            set({board: result, balance: ethBalance})
        } catch (error) {
            console.error('Error calling get_user_board:', error);
        }
    },

    investInDEGY: async (ref="0x0000000000000000000000000000000000000000", amount) => {
        console.log(ref)
        const {daiContractWithSigner} = get();
        try {
            const tx  = await daiContractWithSigner.Invest_in_DEGY(ref, { value: ethers.utils.parseUnits(amount.toString(10), "ether") });

            return tx;
        } catch (err) {
            console.info('err in transaction', err.message);
        }
    },

    // Получить инфо о юзере
    // user - адрес юзера
    // response array of tuple
    // tuple(BigNumber - инвестиция юзера,
    // int - кол-во points,
    // int - кол-во рефералов на 1й линии,
    // int - кол-во рефералов на 2й линии,
    // BigNumber - инвестиции пришедшие от рефералов, int - место в таблице)
    getUserInfo: async (user) => {
        const {contract} = get();
        try{
            const response = await contract.get_user_info(user);
            let userInfo = response.map((info) => {
                return ethers.utils.formatUnits(info, 18)
            })

            set({userInfo})
        }
        catch (err) {
            console.info('err', err.message);
        }
    },

    // Получить инвестиции обратно в конце аукциона(работает только
    // если человек не вошел в ТОП)
    getInvestBack: async () => {
        const {contract} = get();
        try{
            const response = await contract.get_invest_back();
            console.log('invest back', response)
        }
        catch (err) {
            console.info('err', err.message);
        }
    },

    // Получить NFT(работает только в конце аука)
    getMyNft: async () => {
        const {contract} = get();
        try{
            const response = await contract.get_my_NFT();
            console.log(response)
        }
        catch (err) {
            console.info('err', err.message);
        }
    },

    logout: () => {
        set({user: null, provider: null, connectedTo: null});
        localStorage.removeItem('isConnected');
        localStorage.removeItem('account');
        localStorage.removeItem('connectedTo');

    }
}))